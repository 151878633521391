import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  receiptList: [],
  count: 0
};

const slice = createSlice({
  name: 'receipt',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET RECEIPTS
    getReceiptListSuccess(state, action) {
      state.isLoading = false;
      state.receiptList = action.payload.data;
      state.count = action.payload.count;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const { onToggleFollow, deleteCustomer } = slice.actions;

// ----------------------------------------------------------------------

export function getReceiptList(selectedCompanyId, serie, filterName, page, rowsPerPage) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/${selectedCompanyId}/receipt/series/${serie}`, {
        params: {
          filterName,
          page,
          rowsPerPage
        }
      });
      dispatch(slice.actions.getReceiptListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
