import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  ticketList: [],
  count: 0
};

const slice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET RECEIPTS
    getTicketListSuccess(state, action) {
      state.isLoading = false;
      state.ticketList = action.payload.data;
      state.count = action.payload.count;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const { onToggleFollow, deleteCustomer } = slice.actions;

// ----------------------------------------------------------------------

export function getTicketList(selectedCompanyId, serie, filterName, desde, hasta, page, rowsPerPage) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/${selectedCompanyId}/ticket/series/${serie}`, {
        params: {
          filterName,
          desde,
          hasta,
          page,
          rowsPerPage
        }
      });
      dispatch(slice.actions.getTicketListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
